<template>
  <div class="container page__inner">
    <div class="page__content--wider">
      <div class="page__head2">
        <div
          class="
            page__head__container
            align-items-center
            justify-content-between
          "
        >
          <div class="page__head__text">
            <h1 class="page__head2__title">Underwriter Dashboard</h1>
            <!-- <div class="page__head_subtitle">
              <p class="page__head2__subtitle">
                where customer state is inactive
              </p>
            </div> -->
          </div>
          <div class="page__head__actions">
            <div class="d-flex">
              <button
                type="button"
                class="
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                @click="toggleFilterModal"
                style="width:165px"
              >
                <span class="d-inline-flex mr-2">filter</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="page__head_subtitle">
        <p class="page__head2__subtitle">
          {{
            fetchAccount.isAssigned
              ? "where loan is assigned"
              : "where loan is not assigned"
          }}
        </p>
      </div> -->
      <div class="page__body">
        <div style="display:flex; width:50%; font-size:14px" v-if="filterText">
          <div style="width:80%">
            {{ filterText }}
          </div>
          <div style="display:flex;width:10%">
            <span style="cursor:pointer;font-size:17px" @click="editFilter">
              <i style="padding-right:5px" class="bi bi-pencil-square"></i>
            </span>
            <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
              <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
            </span>
          </div>
        </div>
        <table class="customtable w-100">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 12%"
              >
                Customer Full Name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 11%"
              >
                Loan Product
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 11%"
              >
                Loan Amount
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 12%"
              >
                Loan Originator
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 11%"
              >
                Branch
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 11%"
              >
                Loan Account Last Modified Date
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 13%"
              >
                Review Level
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 5%"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody v-if="userIsNotAuthorised">
            <tr>
              <td colspan="12">
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    p5
                  "
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="d-block text--danger text--600 text--medium mt-3 mb-5"
                    >{{ errorResponse }}</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="task in tasks" v-bind:key="task.taskKey">
              <td>
                <router-link
                  tag="a"
                  :to="{
                    name: 'underwriting_accounts',
                    params: { id: task.loanAccountKey, taskKey: task.taskKey },
                  }"
                  class="
                    table__body__text table__text
                    text--capital text--primary
                  "
                >
                  {{ task.customerName }}</router-link
                >
              </td>
              <td style="padding-right: 15px;">
                <span class="table__body__text table__text text--capital" style="word-break: break-word;">
                  {{ task.loanProductName }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  ₦{{ task.loanAmount | formatCurrencyPipe }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ task.loanOriginator }}
                </span>
              </td>
              <td style="padding-right: 15px;">
                <span class="table__body__text table__text text--capital" style="word-break: break-word;">
                  {{ task.branchName }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ task.lastModifiedDate | moment }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ task?.currentApprovalLevelName }}
                </span>
              </td>
              <td>
                <router-link
                  tag="a"
                  :to="{
                    name: 'edit_loan_account',
                    params: { loanAccountKey: task.loanAccountKey },
                  }"
                  class="
                    table__body__text table__text
                    text--capital text--primary
                  "
                >
                  Edit</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination" v-if="!userIsNotAuthorised">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              showing
              <!-- <span
                class="d-inline-block text--primary"
                v-if="paging.totalCount == 0"
                >0</span
              >
              <span class="d-inline-block text--primary" v-else>{{
                !paging.hasPreviousPage
                  ? paging.pageIndex
                  : paging.pageSize * (paging.pageIndex - 1) + 1
              }}</span>
              to --> 
              <span class="d-inline-block text--primary">
                <label for="">
                  <select
                    v-model.number="fetchAccount.pageSize"
                    class="select select--lg select--page"
                    @change="changePageSize()"
                  >
                    <option
                      v-for="psize in pagesizes"
                      :key="psize.id"
                      :id="psize.id"
                    >
                      {{ psize.size }}
                    </option>
                  </select>
                </label>
              </span>
              <span class="d-inline-block text--primary">{{
                paging.currentPage || paging.pageIndex
              }}</span>
              to
              <span class="d-inline-block text--primary">{{
                paging.totalPages
              }}</span>
              <!-- <span class="d-inline-block text--primary">{{
                paging.hasNextPage
                  ? paging.pageSize * paging.pageIndex
                  : paging.totalCount
              }}</span> -->
              <!-- of
              <span>{{ paging.totalCount }}</span> -->
            </div>
            <div
              class="
                pagination__buttons
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <button
                class="button button--sm text--regular"
                v-if="paging.hasPreviousPage"
                @click="stepPage('previous')"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <!-- <button
                class="button text--regular button--sm ml-2 button--primary text--white"
              >
                1
              </button>
              <button class="button text--regular button--sm ml-2">2</button>
              <button class="button text--regular button--sm ml-2">3</button> -->
              <div v-if="!isLargeSizePaged" class="d-flex">
                <button
                  class="button text--regular button--sm ml-2"
                  v-for="num in paging.totalPages"
                  :key="num"
                  :class="{
                    'button--primary text--white': (paging.pageIndex === num || paging.currentPage === num),
                  }"
                  :disabled="(paging.pageIndex === num || paging.currentPage === num)"
                  @click="changePage(num)"
                >
                  {{ num }}
                </button>
              </div>
              <div v-else class="text--capital text--regular pagination__text">
                Page
                <span class="d-inline-block text--primary">{{
                  paging.currentPage
                }}</span>
                of
                <span class="d-inline-block text--primary">{{
                  paging.totalPages
                }}</span>
                from <span class="d-inline-block text--primary">{{ paging.totalCount }}</span> Records
              </div>

              <button
                class="button text--regular button--sm ml-2"
                v-if="paging.hasNextPage"
                @click="stepPage('next')"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal modal__right"
        id="statusModal"
        tabindex=""
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__head">
              <div class="modal__head__icon">
                <a
                  data-dismiss="modal"
                  class="modal__close modal__close--black"
                >
                  <ion-icon
                    name="arrow-back-outline"
                    data-dismiss="modal"
                    id="statusFormCloseButton"
                    aria-label="Close"
                    @click="clearStatusForm"
                    :disabled="isSaving"
                  ></ion-icon>
                </a>
              </div>
              <h5 class="modal__head__title text--capital">Change Status</h5>
            </div>
            <div class="modal__body">
              <form class="form" @submit="saveStatusForm">
                <div class="form__item">
                  <div class="form__item mb-0">
                    <label class="form__label">Status</label>
                    <select
                      class="select select--lg"
                      v-model="statusForm.status"
                      v-bind:class="{
                        'input-required': statusFormErr.status,
                      }"
                    >
                      <option :value="null" disabled selected>
                        Select Status
                      </option>
                      <option
                        v-for="item in reviewStatuses"
                        v-bind:key="item"
                        v-bind:value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form__item">
                  <textarea
                    cols="5"
                    rows="5"
                    class="input form__input"
                    placeholder="Comment"
                    v-model="statusForm.approvalComment"
                  ></textarea>
                </div>
                <div class="form__item mt-5">
                  <div class="form__action">
                    <button
                      type="submit"
                      class="
                        button
                        form__button form__button--lg
                        w-30
                        save-changes
                      "
                      v-if="!isSaving"
                    >
                      Update Status
                    </button>
                    <a
                      role="button"
                      class="
                        button
                        form__button form__button--lg
                        w-25
                        save-changes
                      "
                      v-if="isSaving"
                    >
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    </a>
                    <a
                      role="button"
                      data-dismiss="modal"
                      class="
                        modal__close
                        form__action__text form__action__text--danger
                        text--capital
                        ml6
                      "
                      @click="clearStatusForm"
                      v-if="!isSaving"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!--ALERT NOTIFICATION-->
      <div class="toaster show">
        <div
          class="toaster__container"
          v-if="alert.show"
          v-bind:class="{
            'toaster--success': alert.status === 'success',
            'toaster--error': alert.status === 'fail',
            'toaster--warn': alert.status === 'warn',
          }"
        >
          <div class="text-right">
            <span class="fa fa-times" @click="alert.show = false"></span>
          </div>
          <div>
            <span class="d-block text--small text--600 text--white">{{
              alert.title
            }}</span>
            <span class="d-block text--tiny text--400 text--white">{{
              alert.description
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="reloadFilter"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @alertError="alertError"
      :filterHeaderName="'Underwriting Dashboard'"
      filterCategory="underwriting"
    />
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import underwritingService from "@/core/services/underwriting/underwriting.service";
import userService from "@/core/services/other-service/user.service";
export default Vue.extend({
  name: "underwriting-dashboard",
  computed: {
    userIsNotAuthorised() {
      return this.errorCode == 409;
    },
    isLargeSizePaged() {
      return this.paging?.totalPages > 10;
    }
  },
  components: {
    CreateFilter: () => 
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      )
  },
  data: function () {
    return {
      content_header: {
        title: "Underwriting Dashboard",
        subTitle:
          "Setup your underwriting queues here to auto-assign loan applications to underwriters.",
      },

      reviewStatuses: ["Approve", "Reject", "ReworkSelf", "ReworkQueue"],
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      errorCode: '',
      errorResponse: '',
      tasks: [],
      allUnderwriters: [],
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      isDeactivating: false,
      size: 10,
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      fetchModel: {
        userKey: null,
        reviewStatus: null,
        pageIndex: 1,
        pageSize: 10,
      },
      fetchUnderwriters: {
        pageIndex: 1,
        pageSize: 10000,
      },

      fetchAccount: {
        filters: [],
        userKey: null,
        reviewStatus: null,
        pageIndex: 1,
        pageSize: 10,
        isFromFilter: false,
        filterCategory: "",
      },

      showFilterModal: false,
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,

      statusForm: {
        taskKey: null,
        status: null,
        approvalComment: "",
        accessUserKey: null,
      },
      statusFormErr: {
        status: false,
      },
    };
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    async sendFilter(value) {
      const filterArray = value.map(obj => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchAccount.filters = filterArray;
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = this.size;
      this.fetchAccount.filterCategory = "underwriting";
      this.fetchAccount.isFromFilter = true;
      const res = await underwritingService.getUnderwritingTasks(
        this.fetchAccount
      );
      if(res.status) {
        this.alertSuccess("Successfully applied filter");
        this.tasks = res.data.data;
        const data = res.data;
        this.paging = { 
          ...data,
          pageIndex: data.currentPage,
        };
        this.isLoading = false;
        this.filterText = res.data.filterText.replace(/_/g, " ");
        this.filterEdit = res.data.filters;
      } else {
        this.alertError(res.data.message);
      }
    },
    reloadFilter() {
      this.deleteFilter()
      this.fetchAccount.isFromFilter = false;
      this.fetchAccount.filterCategory = "";
      this.size = 10;
      this.fetchAccount.filters = [];
      this.fetchAccount.pageIndex = 1;
      this.getUnderwritingTasks();
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = []
    },
    editFilter() {
      this.showEditedValue = Math.random()
      this.toggleFilterModal();
    },

    async getUnderwritingTasks() {
      try {
        this.tasks = [];
        this.isLoading = true;
        const res = await underwritingService.getUnderwritingTasks(
          this.fetchAccount
        );
        this.tasks = res.data.data.items || res.data.data;
        this.paging = this.fetchAccount.isFromFilter ? res.data : res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.errorCode = e.response.status;
        this.errorResponse = e.response.data;
        const error = e.response.data;
        this.alertError(error);
      }
    },

    preOpenStatusForm(taskKey) {
      this.clearStatusForm();
      this.statusForm.taskKey = taskKey;
    },

    saveStatusForm: async function (e) {
      e.preventDefault();
      if (this.validateStatusForm()) {
        try {
          this.isSaving = true;
          await underwritingService.changeTaskStatus(this.statusForm);
          this.getUnderwritingTasks();
          this.clearStatusForm();
          document.getElementById("statusFormCloseButton").click();
          if (this.statusForm.status == "Approve") {
            this.alertSuccess("Loan Approved");
          } else if (this.statusForm.status == "Reject") {
            this.alertSuccess("Loan Rejected");
          } else {
            this.alertSuccess("Task Updated");
          }

          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },

    clearStatusForm() {
      this.statusForm = {
        taskKey: null,
        status: null,
        approvalComment: "",
        accessUserKey: null,
      };
    },
    validateStatusForm() {
      this.clearStatusFormValidation();
      if (this.statusForm.status) {
        return true;
      }

      for (const key in this.statusFormErr) {
        if (Object.hasOwnProperty.call(this.statusFormErr, key)) {
          if (
            this.statusForm[key] == null ||
            this.statusForm[key] == undefined ||
            this.statusForm[key].trim() == ""
          ) {
            this.statusFormErr[key] = true;
          }
        }
      }
      return false;
    },
    clearStatusFormValidation() {
      this.statusFormErr = {
        status: false,
      };
    },
    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    // Paging
    changePage(pageNum) {
      this.fetchAccount.pageIndex = pageNum;
      this.fetchAccount.currentPage = pageNum;
      this.getUnderwritingTasks();
    },
    changePageSize() {
      this.getUnderwritingTasks();
    },
    stepPage(direction) {
      if (direction == "next") {
        this.fetchAccount.pageIndex = this.paging.pageIndex ? this.paging.pageIndex + 1 : this.paging.currentPage + 1;
        this.getUnderwritingTasks();
        return;
      }
      if (direction == "previous") {
        this.fetchAccount.pageIndex = this.paging.pageIndex ? this.paging.pageIndex - 1 : this.paging.currentPage - 1;
        this.getUnderwritingTasks();
        return;
      }
    },
    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
    async getUnderwiters() {
      try {
        this.allUnderwriters = [];
        this.isLoading = true;
        const res = await userService.getUnderwriterUsers(
          this.fetchUnderwriters
        );
        this.allUnderwriters = res.data.data.items || res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.errorCode = e.response.status;
        this.errorResponse = e.response.data;
        const error = e.response.data;
        this.alertError(error);
      }
    }
  },
  async created() {
    this.getUnderwritingTasks();
    this.getUnderwiters();
  },
  filters: {
    formatDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
    formatCurrencyPipe: function (number) {
      if (number == null || number == undefined || number == "") return "0.00";
      const n = Number(number);
      return n.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
  },
});
</script>
